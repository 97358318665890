import Image from 'next/image';
import React from 'react';
import SVG from 'react-inlinesvg';
import styles from './styles.module.scss';
import { levelColor } from '../../LevelColor';

const UserTooltipGems = (props) => {
    return (
        <div className="gems_user_tooltip">
            <div className={`${styles.tooltip_gemsbox}`}>
                <div className={`${styles.bg_profile}`}>
                    <SVG
                        src="/assets/social/profile_bg.svg"
                        color={
                            props?.userData?.level > 10
                                ? '#D7B49B'
                                : levelColor(props?.userData?.level)
                        }
                    />

                    <div className={`${styles.user_img}`}>
                        <img src={props?.userData?.image} alt="" />
                    </div>

                    <div className={`${styles.level_img}`}>
                        <SVG src="/assets/social/leval_bg.svg" />
                        <span className={`${styles.level_text}`}>
                            Level{' '}
                            {props?.userData?.level
                                ? props?.userData?.level
                                : 0}
                        </span>
                    </div>
                </div>

                <div className={`${styles.tooltip_gems_details}`}>
                    <div className="mb-3">
                        <p className={`${styles.tooltip_gemslable}`}>
                            {props?.created ? 'Created By' : 'Owned By'}
                        </p>
                        <p className={`${styles.tooltip_gemsdetails}`}>
                            {props?.userData?.username
                                ? props?.userData?.username
                                : props.userData?.public_address?.substring(
                                      0,
                                      6
                                  ) + '...'}
                        </p>
                    </div>
                    <div className={`${styles.tooltip_gems_title_separates}`}>
                        <div className="tooltip_gems_small_img">
                            <Image
                                src="/assets/gemssimple.svg"
                                alt="Logo"
                                layout="fill"
                            />
                        </div>
                        <h2 className={`${styles.tooltip_gemstitles}`}>
                            {`${props?.userData?.gems ? props?.userData?.gems : 0} Gems Earned`}
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserTooltipGems;
