import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './userProfile.module.scss';
const UserProfileLoader = () => {
    return (
        <>
            <div className={`${styles.card}`}>
                <div className={`${styles.card__cover}`}>
                    <Skeleton
                        style={{
                            height: '265px',
                            width: '100%',
                            backgroundcolor: '#F9F9F9',
                        }}
                    />
                    <div className={`${styles.infomain}`}>
                        <Skeleton style={{ height: '20px', width: '100%' }} />
                        <Skeleton style={{ height: '20px', width: '100%' }} />
                        <Skeleton style={{ height: '20px', width: '100%' }} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserProfileLoader;
